import React from "react";
import { Link } from "gatsby";

import BlogPost from "../BlogPost";

function PostListing({ postEdges }) {
  const postList = [];
  postEdges.forEach((postEdge) => {
    postList.push({
      path: postEdge.node.fields.slug,
      tags: postEdge.node.frontmatter.tags,
      cover: postEdge.node.frontmatter.cover,
      title: postEdge.node.frontmatter.title,
      date: postEdge.node.frontmatter.date,
      excerpt: postEdge.node.excerpt,
      timeToRead: postEdge.node.timeToRead,
      html: postEdge.node.html,
    });
  });

  return (
    <div>
      {
        /* Your post list here. */
        postList.map((post) => (
          <div className="mb-20" key={post.title + post.date}>
            <BlogPost
              date={post.date}
              title={post.title}
              html={post.html}
              link={post.path}
            />
            <Link to={post.path} className="text-gray-500 text-sm">
              Permalink
            </Link>
          </div>
        ))
      }
    </div>
  );
}

export default PostListing;
